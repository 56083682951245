//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import SuccessDialog from "@/components/cards/SuccessDialog";
  import api from "@/services/api";
  import { server,leavequotaFile } from "@/services/constants";
  import { language } from "./language/Advancelist.js";
  
  
export default {
    name: "advance-list",
    components: {

    },
    data(vm) {
      return {
        doc_running_id: 0,
        data_item_update: {
            running_next: 0,
            running_year: 0,
        },
        dialogedit: false,
        dialogUpdateAll: false,
        mDataArray: [],
        authorize: [],
        search: "",
        headers: [
        { 
            text: "Module", 
            value: "module" 
        },
        { 
            text: "Prefix", 
            value: "id_prefix" 
        },
        { 
            text: "Running Year", 
            value: "running_year",
            align: "end",
        },
        { 
            text: "Running Len", 
            value: "running_len",
            align: "end",
        },
        { 
            text: "Running Next", 
            value: "running_next",
            align: "end",
        },
        { 
            text: "Status", 
            value: "status",
            align: "center",
        },
        { 
            text: "", 
            value: "actions",
            align: "center",
        },
        ],
        dialogAdd:false,
        text_color: "text-h5 green--text text-center",
        title: "green",
        message: "green",
        link: "",
        numberRules: [
            (v) => /^[0-9]+$/.test(v) || "Please Input Number Only",
        ],
        
      };
    },
    components: {
      SuccessDialog,
    },
    async beforeCreate() {
      //console.log("beforeCreate");
      this.$store.state.navMenu = true;
    },
    async created() {},
    beforeUpdate() {},
    computed: {

    },
    async mounted() {
      await api.checkVersion();
      this.userId = localStorage.getItem(server.USER_ID);
      let yourUrlString = window.location;
      // alert(yourUrlString);
  
      let parser = document.createElement("a");
      parser.href = yourUrlString;
  
    //   this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
  
    //   // alert("authorize_id:" + this.authorize_id);
    //   if (this.authorize_id == null || this.authorize_id == 0) {
    //     // this.$router.push("/login");
    //     this.$store.state.global_dialog = true;
    //     this.setupAlertDialog(
    //       true,
    //       "Authorize Failed",
    //       "Please Logout And Login Again",
    //       "text-h5 red--text text-center"
    //     );
    //     this.$router.back();
    //   }
  
    //   const router_path = parser.pathname.replace("/", "");
  
    //   const res_auth = await api.getAuthorize(this.userId, router_path);
  
    //   // console.log("res_auth:" + JSON.stringify(res_auth.data));
  
    //   this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    //   this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    //   this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    //   this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;
  
   
    //   // this.$router.back();
  
    //   if (!this.authorize_view) {
    //     this.$router.back();
    //   }
  
      // ----------------- Check Authorize ---------------------------
  
      
      // await this.loadCompanyMaster(comp_id);
      // await this.loadDepartmentMaster(comp_id);
      // await this.loadPositionMaster(comp_id);
  
      await this.loadAuthorize();
   
  
      this.userId = localStorage.getItem(server.USER_ID);
      if (this.userId && api.isLoggedIn()) {
        // this.$router.push("/");
      } else {
        this.$store.state.isLogged = false;
        this.$router.push("/login");
      }
      let comp_id = localStorage.getItem(server.COMPANYID);

      await this.loadData();
      this.$hideLoader();
    },
    
    methods: {
        async loadAuthorize() {
            const res_get = await api.getSettingGroupMenu();
            this.authorize = res_get.data;
           
        },
        async loadData(){
            const res_running = await api.getDocRunningByCompany(Number(localStorage.getItem(server.COMPANYID)))
            console.log(res_running.data)
            this.mDataArray = res_running.data;
        },
        openeditItem(data){
            this.doc_running_id = 0;
            this.doc_running_id = data.id;
            this.data_item_update.running_year = data.running_year;
            this.data_item_update.running_next = data.running_next;
            this.dialogedit = true;
        },
        async savedataedit(){
            if (this.data_item_update.running_next == ""  || !/^[0-9]+$/.test(this.data_item_update.running_next)) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please enter all the details.",
                "text-h5 red--text text-center"
                );
                return;
            }
            if (
                this.data_item_update.running_year == "" || 
                this.data_item_update.running_year == 0 || 
                !/^[0-9]+$/.test(this.data_item_update.running_year)
            ) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please enter all the details.",
                "text-h5 red--text text-center"
                );
                return;
            }
            
            this.$showLoader()
            const res_update = await api.updateDocRunning( this.doc_running_id, this.data_item_update );
            console.log(res_update.status)
            if(res_update.status == 200){
                this.dialogedit = false;
                await this.loadData();
                this.$hideLoader();
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Information Saved",
                "Your information has been saved docrunning.",
                "text-h5 green--text text-center"
                );
                return;
            }
        },
        canceledit(){
            this.dialogedit = false;
        },
        updateDataAll(){
            this.dialogUpdateAll = true;
        },
        async saveUpdateAll(){
            this.dialogUpdateAll = false;

            this.$showLoader()
            const res_update_all = await api.updateDocRunningAll({ company_id: Number(localStorage.getItem(server.COMPANYID))});
            if(res_update_all.status == 200){
                await this.loadData();
                this.$hideLoader();
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Information Saved",
                "Your information has been saved docrunning.",
                "text-h5 green--text text-center"
                );
                return;
            }
        },
        closeUpdateAll(){
            this.dialogUpdateAll = false;
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
    },
  };
