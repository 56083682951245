var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Training Detail")])],1),_c('v-col',{attrs:{"align":"right","color":"primary","cols":"12","md":"6"}})],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{ref:"type",attrs:{"items":_vm.type_list,"label":"Select Type","outlined":"","dense":""},model:{value:(_vm.dataAdd.type),callback:function ($$v) {_vm.$set(_vm.dataAdd, "type", $$v)},expression:"dataAdd.type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{ref:"sequence",attrs:{"type":"number","outlined":"","dense":"","label":"Sequence"},model:{value:(_vm.dataAdd.sequence),callback:function ($$v) {_vm.$set(_vm.dataAdd, "sequence", $$v)},expression:"dataAdd.sequence"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:"descriptions",attrs:{"outlined":"","dense":"","label":"Descriptions"},model:{value:(_vm.dataAdd.descriptions),callback:function ($$v) {_vm.$set(_vm.dataAdd, "descriptions", $$v)},expression:"dataAdd.descriptions"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"#0A7EA5","dark":"","density":"default"},on:{"click":function($event){return _vm.saveItem()}}},[_c('span',[_vm._v("Add")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1   595959",attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_tranning_d},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-3",attrs:{"items":_vm.type_list,"dense":""},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})]}},{key:"item.sequence",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"type":"number","dense":""},model:{value:(item.sequence),callback:function ($$v) {_vm.$set(item, "sequence", $$v)},expression:"item.sequence"}})]}},{key:"item.descriptions",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"dense":""},model:{value:(item.descriptions),callback:function ($$v) {_vm.$set(item, "descriptions", $$v)},expression:"item.descriptions"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{style:({
                            backgroundColor: '#9E3B1B',
                            color: 'white',
                        }),on:{"click":function($event){return _vm.opendelete(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center","cols":"12","md":"12"}},[_c('v-btn',{staticClass:"mr-5 buttonblue",attrs:{"depressed":"","width":"100","disabled":_vm.data_tranning_d.length == 0},on:{"click":function($event){return _vm.UpdateItem()}}},[_vm._v(" Update ")]),_c('v-btn',{attrs:{"depressed":"","outlined":"","width":"100"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Cancel ")])],1)],1),_c('br')],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('SuccessDialogPush',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message,"link":_vm.link}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }