//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Leavelist.js";
import unity from "@/unity/unity";

export default {
  name: "leave-list",
  data(vm) {
    return {
      page: 1, // หน้าเริ่มต้น
      itemsPerPage: 3, // จำนวนรายการต่อหน้า
      leavehistory_list: [],
      search_status: "",
      day_sick: 0,
      day_sick_h: 0,
      day_business: 0,
      day_business_h: 0,
      day_vacation: 0,
      day_vacation_h: 0,
      day_oth: 0,
      day_oth_h: 0,
      day_use_leave: 0,
      day_use_leave_d: 0,
      day_use_leave_h: 0,
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Request", "Approve", "Inprocess", "Reject"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      headers: [
        {
          text: "No.",
          value: "id",
          class: "bg-colorth tabletextwhite",
          width: "50px",
          sortable: false,
        },
        {
          text: "Date from",
          value: "startDate2",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Date to",
          value: "endDate2",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Requested",
          value: "min_amount",
          class: "bg-colorth tabletextwhite",
        },

        {
          text: "Leave Type",
          value: "leave_type",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Status",
          value: "approve_name",
          class: "bg-colorth tabletextwhite",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dataAll: [],
      TypeLeaveArray: [],
    };
  },

  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },

  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
    pageCount() {
      return Math.ceil(this.leavehistory_list.length / this.itemsPerPage); // คำนวณจำนวนหน้าทั้งหมด
    },
    paginatedItems() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.leavehistory_list.slice(start, end); // ดึงรายการที่ต้องแสดงในแต่ละหน้า
    }
  },

  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loadQuotaType();
    await this.loadleaveHistory();
    this.$hideLoader();
  },
  methods: {
    async loadleaveHistory(){      
      const res_leave_history = await api.getListHistoryAll(localStorage.getItem(server.USER_ID));

      for( const [index,item] of res_leave_history.data.entries()){
        if(item.leave_type == "Sick Leave"){
          item.leave_type = "Sick"
        }else if(item.leave_type == "Vacation Leave"){
          item.leave_type = "Vacation"
        }
        var leave_day = 0;
        var leave_hours = 0;

        leave_day = Math.floor(Number(item.hours_amount) / 8)
        leave_hours =Number(item.hours_amount) - (leave_day * 8);

        if(leave_day == 0){
          if(leave_hours == 0){
            item.description = `${item.data_date}`
          }else{
            item.description = item.description = `${item.data_date} (${leave_hours} hours)`
          }
        }else{
          if(leave_hours == 0){
            item.description = `${item.data_date} (${leave_day} day)`
          }else{
            item.description = item.description = `${item.data_date} (${leave_day} day ${leave_hours} hours)`
          }
        }
       
      }
      this.leavehistory_list = res_leave_history.data
 
    },
    async loadQuotaType(){
      const res_q = await api.getListUseByUserIDV2byCompany(
        Number(localStorage.getItem(server.USER_ID)),
        Number(localStorage.getItem(server.COMPANYID))
      );

      var leave_type_box = [];
      for(const[index,item] of res_q.data.entries()){
        let json_psuh_array = {
          type: item.leave_type
        }
        
        this.day_use_leave += Number(item.user_day);

        if (item.quota_day) {
          item.user_d = parseInt(Number(item.balance));
          item.user_h = (item.balance - item.user_d) * 8;
        }
        leave_type_box.push(item)
      }

      this.day_use_leave_d = parseInt(this.day_use_leave);
      this.day_use_leave_h = (this.day_use_leave - parseInt(this.day_use_leave)) * 8;

      this.TypeLeaveArray = leave_type_box.reduce((acc, leave) => {
        const found = acc.find(item => item.leave_type === leave.leave_type);
        if (found) {
          found.user_d += leave.user_d;
          found.user_h += leave.user_h;
        } else {
          acc.push({ ...leave });
        }
        return acc;
      }, []);

      console.log(this.TypeLeaveArray)

    },
    async openLeave(id,status,type_count){
      if(status == 1){
        if(type_count){
          this.$router.push(`/leave-request-edit/${id}`);
        }else{
          this.$router.push(`/leave-request-waiting/${id}`);
        }
      }else{
        this.$router.push(`/leave-request-waiting/${id}`);
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page -= 1;
      }
    },
    nextPage() {
      if (this.page < this.pageCount) {
        this.page += 1;
      }
    },

  },
};
