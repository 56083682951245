//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server, imageUrl } from "@/services/constants";
import StockCard from "@/components/cards/StockCard";

export default {
  name: "Dashboard",
  data() {
    return {
      th_name: "",
      eng_name: "",
      sick_day: 0,
      sick_hours: 0.00,
      vacation_day: 0,
      vacation_hours: 0.00,
      leavehistory_list: [],
      leaveApprove_list: []
    };
  },
  components: {
    StockCard,
  },
  async mounted() {
    await api.checkVersion();

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
    //  await api.checkttoken();
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    localStorage.setItem(server.MODELMENU, 0);
    await this.loadEmpdata();
    await this.loadQuota();
    await this.loadleaveHistory();
    await this.loadleaveApprove();
    this.$hideLoader();
  },
  methods: {
    async loadEmpdata(){
      const res_emp = await api.getAccountByidMenu(localStorage.getItem(server.USER_ID))

      this.th_name = res_emp.data[0].name;
      this.eng_name = res_emp.data[0].eng_name;

    },
    async loadQuota(){
      const res_quota = await api.getListByUserID(localStorage.getItem(server.USER_ID))

      var sick_leave_can_use = 0;
      var vacation_leave_can_use = 0;
      for( const [index,item] of res_quota.data.entries()){
        if(Number(item.leave_type_id) == 1){
          sick_leave_can_use += Number(item.leave_can_use);
        }
        if(Number(item.leave_type_id) == 3){
          vacation_leave_can_use += Number(item.leave_can_use);
        }
      }

      this.vacation_day = Math.floor(vacation_leave_can_use)
      this.vacation_hours = (vacation_leave_can_use - this.vacation_day) * 8

      this.sick_day = Math.floor(sick_leave_can_use)
      this.sick_hours = (sick_leave_can_use - this.sick_day) * 8

    },
    async loadleaveHistory(){
      const res_leave_history = await api.getListHistory(localStorage.getItem(server.USER_ID));

      for( const [index,item] of res_leave_history.data.entries()){
        if(item.leave_type == "Sick Leave"){
          item.leave_type = "Sick"
        }else if(item.leave_type == "Vacation Leave"){
          item.leave_type = "Vacation"
        }
        var leave_day = 0;
        var leave_hours = 0;

        leave_day = Math.floor(Number(item.hours_amount) / 8)
        leave_hours =Number(item.hours_amount) - (leave_day * 8);

        if(leave_day == 0){
          if(leave_hours == 0){
            item.description = `${item.data_date}`
          }else{
            item.description = item.description = `${item.data_date} (${leave_hours} hours)`
          }
        }else{
          if(leave_hours == 0){
            item.description = `${item.data_date} (${leave_day} day)`
          }else{
            item.description = item.description = `${item.data_date} (${leave_day} day ${leave_hours} hours)`
          }
        }
       
      }
      this.leavehistory_list = res_leave_history.data
    },
    async loadleaveApprove(){
      const res_leave_approve = await api.getListApprove(localStorage.getItem(server.USER_ID));

      for( const [index,item] of res_leave_approve.data.entries()){
        if(item.leave_type == "Sick Leave"){
          item.leave_type = "Sick"
        }else if(item.leave_type == "Vacation Leave"){
          item.leave_type = "Vacation"
        }
        var leave_day = 0;
        var leave_hours = 0;

        leave_day = Math.floor(Number(item.hours_amount) / 8)
        leave_hours =Number(item.hours_amount) - (leave_day * 8);

        if(leave_day == 0){
          if(leave_hours == 0){
            item.description = `${item.data_date}`
          }else{
            item.description = item.description = `${item.data_date} (${leave_hours} hours)`
          }
        }else{
          if(leave_hours == 0){
            item.description = `${item.data_date} (${leave_day} day)`
          }else{
            item.description = item.description = `${item.data_date} (${leave_day} day ${leave_hours} hours)`
          }
        }
        if (item.image != "" && item.image != null) {
          item.image_profile = imageUrl + "/" + item.image;
        }else{
          item.image_profile = ""
        }
       
      }
      this.leaveApprove_list = res_leave_approve.data
    },
    async goToLeaveHistory() {
      this.$router.push('/my-time-off');
    },
    async goToLeaveHistoryById(id,status) {
      if(status == 1){
        this.$router.push(`/leave-request-edit/${id}`);
      }else{
        this.$router.push(`/leave-request-waiting/${id}`);
      }
    },
    async goToLeaveApprove() {
      // เปลี่ยน URL หรือทำการนำทางที่ต้องการ
      this.$router.push('/approver-time-off'); // หากคุณใช้ Vue Router
      // หรือ window.location.href = 'URL ที่ต้องการ'; // หากคุณต้องการเปลี่ยนไปยัง URL อื่น
    },
    async goToLeaveApproveById(id,user_id) {
      // เปลี่ยน URL หรือทำการนำทางที่ต้องการ
      this.$router.push(`/approve-leave-confirm/${id}/${user_id}`); // หากคุณใช้ Vue Router
      // หรือ window.location.href = 'URL ที่ต้องการ'; // หากคุณต้องการเปลี่ยนไปยัง URL อื่น
    }
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
