//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server } from "../services/constants";

import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue-xlsx";
export default {
  mounted() {
    // this.$showLoader();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      lockcollection: false,
      filenameimport: "",
      oldfilenameimport: "",
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      countsuccess: 0,
      countfail: 0,
      datafailleavequota: 0,
      datafailleavetype: 0,
      datafailcompany: 0,
      sheetsdataallfail: [
        {
          name: "sheetsdataallfail",
          data: [],
        },
      ],
      sheetsempfail: [
        {
          name: "sheetsempfail",
          data: [],
        },
      ],
      sheetsleave_typefail: [
        {
          name: "sheetsleave_typefail",
          data: [],
        },
      ],
      sheetscompanyfail: [
        {
          name: "sheetscompanyfail",
          data: [],
        },
      ],

      btnconfirmimportclose: false,
      exportleavequotaempfail: "leavequota_duplicate_data.xlsx",
      exportleavequotaleavetypefail: "leavequota_leavetype_fail.xlsx",
      exportleavequotacompanyfail: "leavequota_company_fail.xlsx",
      exportleavequotadataallfail: "leavequota_dataall_fail.xlsx",
      employeenamefail: false,
      leavetypefail: false,
      btnconfirmimport: false,
      showcheckdatafile: false,
      datafile: null,
      mDataempfail: [],
      mDataleavetypefail: [],
      mDataCompanyfail: [],
      DataAll: [],
      mDataArray: [],
      mDatafailall: [],
      search: "",
      headers: [
        { text: "company id", value: "company_id" },
        { text: "employee_id", value: "employee_id" },
        { text: "employee_name", value: "employee_name" },
        { text: "leave_period_from", value: "leave_period_from" },
        { text: "leave_period_to", value: "leave_period_to" },
        { text: "leave_type", value: "leave_type" },
        { text: "quota", value: "quota" },
        { text: "use_leave", value: "use_leave" },
        // { text: "status", value: "status" },
      ],
      isShowPassword: false,
      fullPage: true,
    };
  },
  methods: {
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async confirmimport() {
      this.btnconfirmimportclose = true;
      console.log(this.mDataArray)

      this.$showLoader();
      const result = await api.importxlsxLeave_quota(localStorage.getItem(server.COMPANYID),this.mDataArray);
      console.log(result.data)
      let dataresult = result.data;
      this.$hideLoader();
      if (
        dataresult.countsuccess === this.mDataArray.length &&
        dataresult.countfail === 0
      ) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center",
          "/leave-quota"
        );
        return;
      }
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Information Saved",
        "มีข้อมูลผิดพลาด",
        "text-h5 red--text text-center"
      );

      this.countsuccess = dataresult.countsuccess;
      this.countfail = dataresult.countfail;
      


      this.datafailleavequota = dataresult.datafailleavequota.length;
      if (dataresult.datafailleavequota.length > 0) {
        let setdataexportarray = [];
        this.mDataempfail = dataresult.datafailleavequota;
        this.mDataempfail.forEach((x) => {
          let setdataexport = {
            company_id: "",
            employee_id: "",
            employee_name: "",
            leave_period_from: "",
            leave_period_to: "",
            leave_type_id: "",
            leave_type: "",
            quota: "",
            use_leave: "",
          };
          setdataexport.company_id = x.company_id;
          setdataexport.employee_id = x.employee_id;
          setdataexport.employee_name = x.employee_name;
          setdataexport.leave_period_from = x.leave_period_from;
          setdataexport.leave_period_to = x.leave_period_to;
          setdataexport.leave_type_id = x.leave_type_id;
          setdataexport.leave_type = x.leave_type;
          setdataexport.quota = x.quota;
          setdataexport.use_leave = x.use_leave;
          setdataexportarray.push(setdataexport);
        });

        this.sheetsempfail[0].data = setdataexportarray;
        this.employeenamefail = true;
      }

      this.datafailcompany = dataresult.datafailcompany.length;
      if (dataresult.datafailcompany.length > 0) {
        let setdataexportarray = [];
        this.mDataCompanyfail = dataresult.datafailcompany;
        this.mDataCompanyfail.forEach((x) => {
          let setdataexport = {
            company_id: "",
            employee_id: "",
            employee_name: "",
            leave_period_from: "",
            leave_period_to: "",
            leave_type_id: "",
            leave_type: "",
            quota: "",
            use_leave: "",
          };
          setdataexport.company_id = x.company_id;
          setdataexport.employee_id = x.employee_id;
          setdataexport.employee_name = x.employee_name;
          setdataexport.leave_period_from = x.leave_period_from;
          setdataexport.leave_period_to = x.leave_period_to;
          setdataexport.leave_type_id = x.leave_type_id;
          setdataexport.leave_type = x.leave_type;
          setdataexport.quota = x.quota;
          setdataexport.use_leave = x.use_leave;
          setdataexportarray.push(setdataexport);
        });

        this.sheetscompanyfail[0].data = setdataexportarray;

      }

      this.datafailleavetype = dataresult.datafailleavetype.length;
      if (dataresult.datafailleavetype.length > 0) {
        let setdataexportarray = [];
        this.mDataleavetypefail = dataresult.datafailleavetype;
        this.mDataleavetypefail.forEach((x) => {
          let setdataexport = {
            company_id: "",
            employee_id: "",
            employee_name: "",
            leave_period_from: "",
            leave_period_to: "",
            leave_type_id: "",
            leave_type: "",
            quota: "",
            use_leave: "",
          };
          setdataexport.company_id = x.company_id;
          setdataexport.employee_id = x.employee_id;
          setdataexport.employee_name = x.employee_name;
          setdataexport.leave_period_from = x.leave_period_from;
          setdataexport.leave_period_to = x.leave_period_to;
          setdataexport.leave_type_id = x.leave_type_id;
          setdataexport.leave_type = x.leave_type;
          setdataexport.quota = x.quota;
          setdataexport.use_leave = x.use_leave;
          setdataexportarray.push(setdataexport);
        });

        this.sheetsleave_typefail[0].data = setdataexportarray;
        this.leavetypefail = true;
      }

      if (dataresult.datafaileall.length > 0) {
        let setdataexportarray = [];
        this.mDatafailall = dataresult.datafaileall;
        this.mDatafailall.forEach((x) => {
          let setdataexport = {
            company_id: "",
            employee_id: "",
            employee_name: "",
            leave_period_from: "",
            leave_period_to: "",
            leave_type_id: "",
            leave_type: "",
            quota: "",
            use_leave: "",
          };
          setdataexport.company_id = x.company_id;
          setdataexport.employee_id = x.employee_id;
          setdataexport.employee_name = x.employee_name;
          setdataexport.leave_period_from = x.leave_period_from;
          setdataexport.leave_period_to = x.leave_period_to;
          setdataexport.leave_type_id = x.leave_type_id;
          setdataexport.leave_type = x.leave_type;
          setdataexport.quota = x.quota;
          setdataexport.use_leave = x.use_leave;
          setdataexportarray.push(setdataexport);
        });
        this.sheetsdataallfail[0].data = setdataexportarray;
      }
    },
    async getInfo(data) {},
    async Readleavequota(event) {
      this.mDataArray = [];
      this.datafile = null;
      this.datafile = event.target.files ? event.target.files[0] : null;

      this.filenameimport = this.datafile.name;

      this.lockcollection = true;
    },
    importconversedate(input) {
      let date;

      // ตรวจสอบประเภทของ input
      if (typeof input === 'string') {
        date = new Date(input);
      } else if (input instanceof Date) {
        date = input;
      } else if (typeof input === 'number') {
        // ถ้า input เป็นตัวเลข ให้แปลงจากจำนวนวันตั้งแต่วันที่ 1 มกราคม 1970
        date = new Date((input - 25569) * 86400 * 1000); // แปลงตัวเลขเป็นวันที่
      } else {
        throw new Error('Invalid date input. Must be a string, Date object, or number.');
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // เดือนเริ่มที่ 0
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    onParsed(data) {

      this.datafailleavequota = 0;
      this.datafailleavetype = 0;
      this.countsuccess = 0;
      this.countfail = 0;
      this.employeenamefail = false;
      this.leavetypefail = false;
      this.mDataempfail = [];
      this.mDataleavetypefail = [];

      (this.sheetsdataallfail = [
        {
          name: "sheetsdataallfail",
          data: [],
        },
      ]),
        (this.sheetsempfail = [
          {
            name: "sheetsempfail",
            data: [],
          },
        ]),
        (this.sheetsleave_typefail = [
          {
            name: "sheetsleave_typefail",
            data: [],
          },
        ]),
        (this.sheetscompanyfail = [
          {
            name: "sheetscompanyfail",
            data: [],
          },
        ]),
        (this.mDataArray = data);
      this.mDataArray.forEach((item, index) => {

        item.company_id = item.company_id;
        item.leave_period_from = this.importconversedate(item.leave_period_from);
        item.leave_period_to = this.importconversedate(item.leave_period_to);

      });

      this.btnconfirmimportclose = false;
      this.btnconfirmimport = true;
      this.lockcollection = false;
    },
  },

  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
