//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
export default {
  components: {
    SuccessDialog,
  },
  data: () => ({
    dialogAdd: false,
    text_color: "text-h5 green--text text-center",
    title: "green",
    message: "green",
    search: "",
    dialogadd: false,
    dialogedit: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Position Name", value: "name" },
      // { text: "address", value: "address" },
      // { text: "provice", value: "provice" },
      // { text: "zipcode", value: "zipcode" },
      { text: "Company Name", value: "companyName" },
      { text: "Department Name", value: "departmentName" },

      { text: "Status", value: "status" },
      { text: "Action", value: "actions" },
    ],
    desserts: [],
    itemadd: {
      name: "",
      company_id: 0,
      department_id: 0,
      status: "",
      user_create: localStorage.getItem(server.USER_ID),
    },
    itemeditid: 0,
    itemedit: {
      name: "",
      company_id: 0,
      department_id: 1,
      status: "",
      user_update: localStorage.getItem(server.USER_ID),
    },
    companyAll: [],
    departmentShow: [],
    departmentShowEdit: [
      {
        id: 0,
      },
    ],
    statusall: ["ACTIVE", "INACTIVE"],
  }),
  async mounted() {
    await api.checkVersion();
    // ----------------- Check Authorize ---------------------------
    const userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let token = localStorage.getItem(server.USER_ID);

    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.getcompanydata();
    await this.loadCompany();
  },
  methods: {
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    async loadCompany(id) {
      // const result = await api.getAllCompany(id);
      // this.companyAll = result.data;

      var result;

      // if (localStorage.getItem(server.ROLE) == "ADMIN") {
      //   result = await api.getAllCompany();
      // } else {
      result = await api.getCompanyByID(localStorage.getItem(server.COMPANYID));
      // }
      this.companyAll = result.data;
    },
    async getcompanydata() {
      // const result = await api.getAllPosition();
      // this.desserts = result.data;

      var result;

      // if (localStorage.getItem(server.ROLE) == "ADMIN") {
      //   result = await api.getAllPosition();
      // } else {
      result = await api.getAllPosition_By_CompanyID(
        localStorage.getItem(server.COMPANYID)
      );
      // }
      this.desserts = result.data;
      // this.$showLoader();
      this.$hideLoader();
    },

    openadd() {
      this.itemadd = {
        name: "",
        company_id: 0,
        department_id: 0,
        status: "",
        user_create: localStorage.getItem(server.USER_ID),
      };
      this.dialogadd = true;
    },
    canceladd() {
      this.dialogadd = false;
    },
    canceledit() {
      this.dialogedit = false;
    },
    async savedata() {
      if (this.itemadd.name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter Position Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemadd.company_id == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please select Company",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        this.itemadd.department_id == 0 ||
        this.itemadd.department_id == "0"
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please select Department",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemadd.status == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please select Status",
          "text-h5 red--text text-center"
        );
        return;
      }
      //console.log(this.itemadd)
      const res_add = await api.addPosition(this.itemadd);
      if (res_add.status == 200) {
        //   alert("debug1");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success",
          "Add data success",
          "text-h5 green--text text-center"
        );
        this.getcompanydata();
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Add data Failed",
          "text-h5 red--text text-center"
        );
      }
      this.dialogadd = false;
    },
    async showdepartName() {
      //  getDepartmentByCompanyID
      const result = await api.getAllDepartment_By_CompanyID(
        this.itemadd.company_id
      );

      // console.log("showdepartName result.data" + JSON.stringify(result.data));
      this.departmentShow = result.data;
    },
    async showdepartNameEdit() {
      //  getDepartmentByCompanyID
      const result = await api.getAllDepartment_By_CompanyID(
        this.itemedit.company_id
      );

      //console.log(result.data)
      if (result.data.length == 0) {
        this.itemedit.department_id = 0;
        this.departmentShowEdit = [];
      } else {
        this.departmentShowEdit = result.data;
      }
    },
    async openeditItem(item) {
      // console.log("============================");
      // console.log(item);

      const result = await api.getAllDepartment_By_CompanyID(item.company_id);
      this.departmentShowEdit = result.data;
      console.log(
        "this.departmentShowEdit : " + JSON.stringify(this.departmentShowEdit)
      );

      this.itemeditid = item.id;
      this.itemedit.name = item.name;
      this.itemedit.company_id = item.company_id;
      this.itemedit.department_id = item.department_id;
      this.itemedit.status = item.status;
      this.dialogedit = true;
    },
    async savedataedit() {
      if (this.itemedit.name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter Position Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemedit.company_id == 0 || this.itemedit.company_id == "0") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please select Company",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        this.itemedit.department_id == 0 ||
        this.itemedit.department_id == "0"
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please select Department",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemedit.status == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please select Status",
          "text-h5 red--text text-center"
        );
        return;
      }
      //console.log(this.itemedit)
      const res_update = await api.updatePosition(
        this.itemeditid,
        this.itemedit
      );
      //console.log(res_update);
      if (res_update.status == 200 || res_update.status == 201) {
        //   alert("debug1");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success",
          "Update data success",
          "text-h5 green--text text-center"
        );
        this.getcompanydata();
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Update data Failed",
          "text-h5 red--text text-center"
        );
      }
      // console.log("testdapartshow")
      // console.log(this.departmentShowEdit)

      this.dialogedit = false;
    },
  },
  beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
