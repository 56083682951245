//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import { imageUrl } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "./computing/tolocalestringnumber";
import unity from "@/unity/unity";
import pdfvuer from "pdfvuer";

export default {
  name: "NewAdvance",
  data() {
    return {
      vat_type_list:[{id:"I",name:"Include Vat"},{id:"E",name:"Exclude Vat"}],
      menu: false,
      menu2: false,
      os_h: {
        saleorder_id: "",
        customerid: 0,
        remark: "",
        contact_position: "",
        po_no: "",
        po_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        delivery_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        additional: "",
        status: 0,
        image: "",
      },
      add_sod: {
        sales_order_h_id: 0,
        seq: 0,
        item_name: "",
        item_qty: 0,
        item_price: 0,
        item_line_amt: 0,
        user_create: localStorage.getItem(server.USER_ID),
      },
      add_soh: {
        saleorder_id: "",
        customerid: 0,
        delivery_date: "",
        po_no: "",
        po_date: "",
        remark: "",
        additional: "",
        remark: "",
        contact_position:"",
        status: 0,
        created_at: "",
        updated_at: "",
        user_create: localStorage.getItem(server.USER_ID),
        image: "",
        sum_disc: 0,
        sum_line_amt: 0,
      },
      os_d: {
        sales_order_h_id: null,
        item_name: "",
        item_qty: 0,
        item_price: 0,
        item_line_amt: 0,
        user_update: localStorage.getItem(server.USER_ID),
      },
      dialogDeleteimage: false,

      desserts: [
        {
          no: "1",
          detail: "",
          withdrawamount: 0,
        },
      ],
      headers: [
        {
          text: "No.",
          value: "no",
          class: "bg-colorth texttablehcenter ",
          width: "8%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Service/Description",
          align: "center",
          value: "description",
          class: "bg-colorth texttablehcenter",
          width: "35%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Qty",
          value: "unit",
          class: "bg-colorth texttablehcenter ",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          text: "Price Per Unit",
          value: "PricePerUnit",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Amount",
          value: "amount",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          value: "actions",
          class: "bg-colorth texttablehcenter",
          width: "8%",
          divider: true,
          sortable: false,
        },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        description: "",
        no: 0,
        unit: 0,
        amount: 0,
        PricePerUnit: 0,
      },
      defaultItem: {
        no: 0,
        description: "",
        amount: 0,
        unit: 0,
        PricePerUnit: 0,
      },
      advance_log: {
        module: "SO",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },

      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      customerNameList: [],
      customerList: [
        {
          cust_account: "",
        },
      ],
      cus_id: 0,
      datePO: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateDelivery: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialogDelete: false,
      no_cus: 0,
      sumtotal: 0.0,
      vatCal: 0,
      vatCalSum: 0.0,
      vat: "0.00",
      sumGrandTotal: 0.0,
      preview_list: [],
      image_list: [],
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
      sostatus: false,
      customer_code: "",
      filenameattach: [],
      previewpopupattachfile2: [],
      previewpopupattachfile: [],
      checkattachfileapreview: false,
      dialogpreviewattachfile: false,
      dialogpreviewattachimage: false,
      dialogconfirmdeleteattachfile: false,
      numPagespreviewattach: 0,
      previewattachimage: "",
      indexdeleteattachfile: -1,
      getpreview_listattach: [],
      preview_listattach: [],
      image_listattach: [],
    };
  },
  components: {
    pdf: pdfvuer,
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    computedDatePOFormatted() {
      return unity.formatDate(this.os_h.po_date);
    },
    computedDateDeliveryFormatted() {
      return unity.formatDate(this.os_h.delivery_date);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    // this.$showLoader();
    this.$hideLoader();
    this.$forceUpdate();

    this.loadCustomer();

    // // alert(result.data[0])
    // console.log("testresult")
    //console.log(this.customerNameList)

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    const result = await api.getAllCustomerListactiveCompany({
      params: {
        company_id: localStorage.getItem(server.COMPANYID),
      },
    });
    // console.log("result.data : ", JSON.stringify(result.data));
    this.customer = result.data;

    // const resultlist = await api.getCustomers();
    // this.customerNameList = result.data[0];
    this.customerNameList = result.data;
  },
  methods: {
    async getBackList(){
      this.$router.push(`/so-list`); 
    },
    async previewattachfile(index) {
      if (this.filenameattach[index].split(/[\s.]+/).pop() == "pdf") {
        this.previewpopupattachfile2 = [];
        await this.getPforpreviewattach(this.getpreview_listattach[index]);
        this.previewpopupattachfile2 = this.previewpopupattachfile;
        // console.log(this.previewpopupattachfile2);
        this.checkattachfileapreview = true;
        this.dialogpreviewattachfile = true;
      } else {
        this.previewattachimage = this.getpreview_listattach[index];
        this.dialogpreviewattachimage = true;
      }
    },
    async getPforpreviewattach(datafile) {
      this.previewpopupattachfile = [];
      let getfile = "";
      getfile = datafile;
      this.previewpopupattachfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupattachfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreviewattach = pdf.numPages;
        });
      });
      // console.log(this.previewpopupattachfile);
    },
    async closepreviewattachfile() {
      this.dialogpreviewattachfile = false;
    },
    async closepreviewattachimage() {
      this.dialogpreviewattachimage = false;
    },
    async confirmdeleteattachfile(index) {
      this.indexdeleteattachfile = index;
      this.dialogconfirmdeleteattachfile = true;
    },
    async closedialogconfirmdeleteattachfile() {
      this.indexdeleteattachfile = -1;
      this.dialogconfirmdeleteattachfile = false;
    },
    async deletedialogconfirmdeleteattachfile() {
      this.filenameattach.splice(this.indexdeleteattachfile, 1);
      this.preview_listattach.splice(this.indexdeleteattachfile, 1);
      this.image_listattach.splice(this.indexdeleteattachfile, 1);
      this.getpreview_listattach.splice(this.indexdeleteattachfile, 1);
      this.dialogconfirmdeleteattachfile = false;
    },
    async loadCustomer() {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      // console.log("result.data : ", JSON.stringify(result.data));
      this.customer = result.data;

      // const resultlist = await api.getCustomers();
      // this.customerNameList = result.data[0];
      this.customerNameList = result.data;
      loader.hide();
    },
  async  save() {
      // alert("texttable")
      if (this.editedItem.description == "") {
        // alert("กรุณากรอก Description/Service ");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Invalid data",
          "Please check your data and try again.",
          "text-h5 red--text text-center"
        );
        this.$refs["txtDescription"].focus();
        return;
      }

      if (
        this.editedItem.unit == undefined ||
        this.editedItem.unit == "0" ||
        this.editedItem.unit == 0
      ) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Invalid data",
          "Please check your data and try again.",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (this.editedItem.unit < 0) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Invalid data",
          "Please check your data and try again.",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (
        this.editedItem.PricePerUnit == undefined ||
        this.editedItem.PricePerUnit == "0" ||
        this.editedItem.PricePerUnit == 0
      ) {
        // alert("กรุณากรอกจำนวนเงินต่อหน่วย");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Invalid data",
          "Please check your data and try again.",
          "text-h5 red--text text-center"
        );
        this.$refs["txtPricePerUnit"].focus();
        return;
      }

      if (this.editedItem.PricePerUnit < 0) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Invalid data",
          "Please check your data and try again.",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }
      // if (this.editedItem.amount == 0 ) {
      //   alert("กรุณากรอกจำนวนเงิน");
      //   this.$refs["txtAmount"].focus();
      //   return;
      // }

      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
   await this.calvatAll();
      this.close();
    },
    calvatAll(vat) {
       this.sumtotal = 0;
      this.vatCalSum = 0;
      this.sumGrandTotal = 0;
      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        item.unit = stringcommatonumber(item.unit.toString());
        item.PricePerUnit = stringcommatonumber(item.PricePerUnit.toString());
        item.amount = parseFloat(item.unit * item.PricePerUnit).toFixed(2);
        this.sumtotal += parseFloat(item.amount, 10);
        item.unit = tolocalestringnumber(item.unit);
        item.PricePerUnit = tolocalestringnumber(item.PricePerUnit);
        item.amount = tolocalestringnumber(item.amount);
      });

      if(this.customerList[0].vat_type == "E"){
        this.sumtotal = (this.sumtotal).toFixed(2);
      this.vatCalSum = (this.sumtotal * (this.vat / 100)).toFixed(2);
      this.sumGrandTotal = (this.sumtotal * (1 + this.vat / 100)).toFixed(2);
      }else if(this.customerList[0].vat_type == "I"){
        this.sumGrandTotal = this.sumtotal.toFixed(2);
        this.sumtotal = (this.sumGrandTotal / (1 + this.vat / 100)).toFixed(2);
        this.vatCalSum = (this.sumGrandTotal - this.sumtotal).toFixed(2);
      }else{
      }
      
      this.sumtotal = tolocalestringnumber(this.sumtotal);
      this.vatCalSum = tolocalestringnumber(this.vatCalSum);
      this.sumGrandTotal = tolocalestringnumber(this.sumGrandTotal);
      this.close();
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async saveSOdraftstatus() {
      if (this.cus_id == 0 || this.cus_id == "0") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Invalid data",
          "Please check your data and try again.",
          "text-h5 red--text text-center"
        );
        return;
      }
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      let formData = new FormData();
      this.image_listattach.forEach((x, index) => {
        formData.append(`file`, x);
      });
      formData.append("customerid", this.cus_id);
      formData.append("customer_address", this.customerList[0].address);
      formData.append("company_id", localStorage.getItem(server.COMPANYID));
      formData.append(
        "customer_inv_address",
        this.customerList[0].address_invoice
      );
      formData.append("payment_term", this.customerList[0].payment_term);
      formData.append("currency", this.customerList[0].currency);
      formData.append("vat", this.vat);
      formData.append("vat_type", this.customerList[0].vat_type);
      formData.append("delivery_date", this.os_h.delivery_date);
      formData.append("po_no", this.os_h.po_no);
      formData.append("po_date", this.os_h.po_date);
      formData.append("remark", this.os_h.remark);
      formData.append("contact_position", this.os_h.contact_position);
      formData.append("additional", this.os_h.additional);
      formData.append(
        "sum_disc",
        stringcommatonumber(this.sumtotal.toString())
      );
      formData.append(
        "sum_tax",
        stringcommatonumber(this.vatCalSum.toString())
      );
      formData.append(
        "sum_line_amt",
        stringcommatonumber(this.sumGrandTotal.toString())
      );
      formData.append("user_create", localStorage.getItem(server.USER_ID));
      formData.append("status", 1);

      const createso_h = await api.createSalesOrderH(formData);
      //alert(createso_h.status);
      if (createso_h.status == 200 || createso_h.status == 201) {
        if (this.desserts.length > 0) {
          this.desserts.forEach((item) => {
            this.add_sod.sales_order_h_id = createso_h.data.id;
            this.add_sod.seq = item.no;
            this.add_sod.item_name = item.description;
            this.add_sod.item_qty = stringcommatonumber(item.unit.toString());
            this.add_sod.item_price = stringcommatonumber(
              item.PricePerUnit.toString()
            );
            this.add_sod.item_line_amt = stringcommatonumber(item.amount);
            api.addDetailSO(this.add_sod);
          });
        }
        this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
        this.advance_log.approve_status = 1;
        this.advance_log.approve_date = new Date();
        const res_log = await api.addAdvanceLog(this.advance_log);
        // alert(res_log.status)
        if (res_log.status == 200 || res_log.status == 201) {
          //alert("testaddSOD")
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Your information has been saved in draft.",
            "text-h5 green--text text-center",
            "/so-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
      loader.hide();
    },
    async showCusName() {
      //alert(this.cus_id)

      const result = await api.getCustomersById(this.cus_id);
      // this.customerNameList = result.data[0];
      this.customerList = result.data;
      this.customer_code = this.cus_id;
      this.vatCal = parseInt(this.customerList[0].vat);
      this.vat = this.customerList[0].vat;
      this.os_h.remark = this.customerList[0].contact_name;
      this.os_h.contact_position=  this.customerList[0].contact_position==null?"":this.customerList[0].contact_position;
      // console.log(this.customerList)
    },
    async showCode() {
      //alert(this.customer_code)

      const result = await api.getCustomersById(this.customer_code);
      // this.customerNameList = result.data[0];
      this.customerList = result.data;
      this.cus_id = this.customer_code;
      this.vatCal = parseInt(this.customerList[0].vat);
      this.vat = this.customerList[0].vat;
      this.os_h.remark = this.customerList[0].contact_name;
      this.os_h.contact_position=  this.customerList[0].contact_position==null?"":this.customerList[0].contact_position;
      // console.log(this.customerList)
    },
    async saveSOsubmit() {
      if (this.cus_id == 0 || this.cus_id == "0") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Invalid data",
          "Please check your data and try again.",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.desserts.length <= 0) {
      //   // alert("กรุณาเลือกลูกค้า");
      //   // this.messageSaveDraft = "กรุณากรอกรายละเอียด Advance Detail";
      //   // this.dialogSaveDraft = true;
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Check your information again",
      //     "กรุณากรอกรายละเอียด SO Detail",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      let formData = new FormData();
      this.image_listattach.forEach((x, index) => {
        formData.append(`file`, x);
      });

      formData.append("customerid", this.cus_id);
      formData.append("customer_address", this.customerList[0].address);
      formData.append("company_id", localStorage.getItem(server.COMPANYID));
      formData.append(
        "customer_inv_address",
        this.customerList[0].address_invoice
      );
      formData.append("payment_term", this.customerList[0].payment_term);
      formData.append("currency", this.customerList[0].currency);
      formData.append("vat", this.vat);
      formData.append("vat_type", this.customerList[0].vat_type);
      formData.append("delivery_date", this.os_h.delivery_date);
      formData.append("po_no", this.os_h.po_no);
      formData.append("po_date", this.os_h.po_date);
      formData.append("remark", this.os_h.remark);
      formData.append("contact_position", this.os_h.contact_position);
      formData.append("additional", this.os_h.additional);
      formData.append(
        "sum_disc",
        stringcommatonumber(this.sumtotal.toString())
      );
      formData.append(
        "sum_tax",
        stringcommatonumber(this.vatCalSum.toString())
      );
      formData.append(
        "sum_line_amt",
        stringcommatonumber(this.sumGrandTotal.toString())
      );
      formData.append("user_create", localStorage.getItem(server.USER_ID));
      formData.append("status", 2);

      const createso_h = await api.createSalesOrderH(formData);
      //alert(createso_h.status);
      if (createso_h.status == 200 || createso_h.status == 201) {
        if (this.desserts.length > 0) {
          this.desserts.forEach((item) => {
            this.add_sod.sales_order_h_id = createso_h.data.id;
            this.add_sod.seq = item.no;
            this.add_sod.item_name = item.description;
            this.add_sod.item_qty = stringcommatonumber(item.unit.toString());
            this.add_sod.item_price = stringcommatonumber(
              item.PricePerUnit.toString()
            );
            this.add_sod.item_line_amt = stringcommatonumber(item.amount);
            api.addDetailSO(this.add_sod);
          });
        }
        this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
        this.advance_log.approve_status = 2;
        this.advance_log.approve_date = new Date();
        const res_log = await api.addAdvanceLog(this.advance_log);
        if (res_log.status == 200 || res_log.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Submitted",
            "Information submit and sent to Approver.",
            "text-h5 green--text text-center",
            "/so-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
      loader.hide();
    },
   async closeDelete() {
      await this.calvatAll();
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },

    initialize() {},
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      this.filenameattach = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            // this.preview_list.push(e.target.result);
            this.getpreview_listattach.push(e.target.result);
            this.preview_listattach.push(e.target.result);
          };
          if (
            input.files[index].type === "application/pdf" ||
            input.files[index].type.split("/")[0] === "image"
          ) {
            this.image_listattach.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      // console.log(this.image_list);
      this.image_listattach.forEach((x) => {
        this.filenameattach.push(x.name);
      });
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    clickremoveimage(index) {
      this.indeximage = index;
      this.dialogDeleteimage = true;
    },
    async removeimage(index) {
      this.preview_list.splice(this.indeximage, 1);
      this.image_list.splice(this.indeximage, 1);

      this.dialogDeleteimage = false;
    },
    closeDeleteimage() {
      this.indeximage = null;
      this.dialogDeleteimage = false;
    },
    closeSaveDraft() {},
  },
  async beforeCreate() {
    // console.log("beforeCreate");
    this.$store.state.navMenu = true;

    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
