//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

// API Localhost
// export const apiUrl = "http://localhost:3000";
// export const imageUrl = "http://localhost/image";

export const apiKeyTiny = "iwpxfawesh9p955xh8fpa9bnfe3kn93aj5mef6n8ds67h9xg";

// export const apiUrl = "http://localhost:9293";
// export const imageUrl = "http://localhost:9293/image";  
// export const fileSaleorderUrl = "http://localhost:9293/saleorderfiles";
// export const imageSaleorderUrl = "http://localhost:9293/saleorderimages";
// export const fileClearAdvance = "http://localhost:9293/clearadvancefiles";
// export const imageClearAdvance = "http://localhost:9293/clearadvanceimages";
// export const fileImmigration = "http://localhost:9293/immigrationfiles";
// export const fileImmigrationTransaction ="http://localhost:9293/immigrationtransactionfiles";
// export const filesDdocfile= "http://localhost:9293/d_docfile";
// export const pettyCashFile= "http://localhost:9293/petty_cash_file";
// export const pettyCashImage= "http://localhost:9293/petty_cash_image";
// export const leavequotaFile= "http://localhost:9293/leavequota";
// //-----------at law --------------//
// export const imagemasterTMUrl = "http://localhost:9293/mastertmimages";
// export const filemasterTMUrl = "http://localhost:9293/mastertmfiles";
// export const imageappointmentTMUrl = "http://localhost:9293/appointmenttmimages";
// export const fileappointmentTMUrl = "http://localhost:9293/appointmenttmfiles";
// export const imagemasterPTUrl = "http://localhost:9293/masterptimages";
// export const filemasterPTUrl = "http://localhost:9293/masterptfiles";
// export const POAUrl = "http://localhost:9293/poa_file";
// export const INVUrl = "http://localhost:9293/inv_file";



// API Dev
export const apiUrl = "https://apidtimedev.dcm.co.th/";
export const imageUrl = "https://apidtimedev.dcm.co.th/image";
export const fileSaleorderUrl = "https://apidtimedev.dcm.co.th/saleorderfiles";
export const imageSaleorderUrl = "https://apidtimedev.dcm.co.th/saleorderimages";
export const fileClearAdvance = "https://apidtimedev.dcm.co.th/clearadvancefiles";
export const imageClearAdvance = "https://apidtimedev.dcm.co.th/clearadvanceimages";
export const fileImmigration = "https://apidtimedev.dcm.co.th/immigrationfiles";
export const fileImmigrationTransaction = "https://apidtimedev.dcm.co.th/immigrationtransactionfiles";
export const filesDdocfile = "https://apidtimedev.dcm.co.th/d_docfile";
export const pettyCashFile= "https://apidtimedev.dcm.co.th/petty_cash_file";
export const pettyCashImage= "https://apidtimedev.dcm.co.th/petty_cash_image";
export const leavequotaFile= "https://apidtimedev.dcm.co.th/leavequota";
//-----------at law --------------//
export const imagemasterTMUrl = "https://apidtimedev.dcm.co.th/mastertmimages";
export const filemasterTMUrl = "https://apidtimedev.dcm.co.th/mastertmfiles";
export const imageappointmentTMUrl = "https://apidtimedev.dcm.co.th/appointmenttmimages";
export const fileappointmentTMUrl = "https://apidtimedev.dcm.co.th/appointmenttmfiles";
export const imagemasterPTUrl = "https://apidtimedev.dcm.co.th/masterptimages";
export const filemasterPTUrl = "https://apidtimedev.dcm.co.th/masterptfiles";
export const POAUrl = "https://apidtimedev.dcm.co.th/poa_file";
export const INVUrl = "https://apidtimedev.dcm.co.th/inv_file";


// API Production
// export const apiUrl = "https://apidtime.dcm.co.th/";
// export const imageUrl = "https://apidtime.dcm.co.th/image";
// export const fileSaleorderUrl = "https://apidtime.dcm.co.th/saleorderfiles";
// export const imageSaleorderUrl = "https://apidtime.dcm.co.th/saleorderimages";
// export const fileClearAdvance = "https://apidtime.dcm.co.th/clearadvancefiles";
// export const imageClearAdvance = "https://apidtime.dcm.co.th/clearadvanceimages";
// export const fileImmigration = "https://apidtime.dcm.co.th/immigrationfiles";
// export const fileImmigrationTransaction = "https://apidtime.dcm.co.th/immigrationtransactionfiles";
// export const filesDdocfile = "https://apidtime.dcm.co.th/d_docfile";
// export const pettyCashFile= "https://apidtime.dcm.co.th/petty_cash_file";
// export const pettyCashImage= "https://apidtime.dcm.co.th/petty_cash_image";
// export const leavequotaFile= "https://apidtime.dcm.co.th/leavequota";
// // -----------at law --------------//
// export const imagemasterTMUrl = "https://apidtime.dcm.co.th/mastertmimages";
// export const filemasterTMUrl = "https://apidtime.dcm.co.th/mastertmfiles";
// export const imageappointmentTMUrl = "https://apidtime.dcm.co.th/appointmenttmimages";
// export const fileappointmentTMUrl = "https://apidtime.dcm.co.th/appointmenttmfiles";
// export const imagemasterPTUrl = "https://apidtime.dcm.co.th/masterptimages";
// export const filemasterPTUrl = "https://apidtime.dcm.co.th/masterptfiles";
// export const POAUrl = "https://apidtime.dcm.co.th/poa_file";
// export const INVUrl = "https://apidtime.dcm.co.th/inv_file";

//api local
// export const apiUrlSME = "http://localhost:8085";
// export const imageUrlSME = "http://localhost:8085";
// export const agreementfileUrl = "http://localhost:8085/agreementfile";
// export const agreementfilepaymodeUrl = "http://localhost:8085/agreementfilepaymode";
// export const reqeuestMassageFileUrl = "http://localhost:8085/requestMassagefile";
// export const reqeuestMassageImageUrl = "http://localhost:8085/requestMassageimage";

//API SME MATE Dev
export const apiUrlSME = "https://api-smemate-dev.dcm.co.th";
export const imageUrlSME = "https://api-smemate-dev.dcm.co.th";
export const agreementfileUrl = "https://api-smemate-dev.dcm.co.th/agreementfile";
export const agreementfilepaymodeUrl = "https://api-smemate-dev.dcm.co.th/agreementfilepaymode";
export const reqeuestMassageFileUrl = "https://api-smemate-dev.dcm.co.th/requestMassagefile";
export const reqeuestMassageImageUrl = "https://api-smemate-dev.dcm.co.th/requestMassageimage";

//API SME MATE Poduction
// export const apiUrlSME = "https://api-smemate.dcm.co.th";
// export const imageUrlSME = "https://api-smemate.dcm.co.th";
// export const agreementfileUrl = "https://api-smemate.dcm.co.th/agreementfile";
// export const agreementfilepaymodeUrl = "https://api-smemate.dcm.co.th/agreementfilepaymode";
// export const reqeuestMassageFileUrl = "https://api-smemate.dcm.co.th/requestMassagefile";
// export const reqeuestMassageImageUrl = "https://api-smemate.dcm.co.th/requestMassageimage";



export const server = {
  //--------URL dtime-------------------//
  ADVANCE_CODE_URL: `advance_code`,
  ADVANCE_LOG_URL: `advance_log`,
  ADVANCE_H_URL: `advance_h`,
  ADVANCE_D_URL: `advance_d`,
  TIMESHEET_H_URL: `timesheet_h`,
  TIMESHEET_D_URL: `timesheet_d`,
  LOGIN_URL: `login`,
  VERSION: `version`,
  EMAIL_URL: `email`,
  ACCOUNT_URL: `account`,
  REGISTER_URL: `register`,
  PRODUCT_URL: `product`,
  TRANSACTION_URL: `transaction`,
  REPORT_URL: `report`,
  TOKEN_KEY: `token`,
  USERNAME: `username`,
  USER_ID: `user_id`,
  FULLNAME: `fullname`,
  ROLE: `role`,
  COMPANYID: `company_id`,
  DEPARTMENT_ID: `department_id`,
  POSITION_ID: `position_id`,
  AUTHORIZE_ID: `authorize_id`,
  CUSTOMER_URL: `customer`,
  CURRENCY_URL: `currency`,
  GROUP_MENU_URL: `group-menu`,
  MENU_DETAIL_URL: `menu-detail`,
  SETTING_GROUP_MENU_URL: `setting-group-menu`,
  SETTING_MENU_DETAIL_URL: `setting-menu-detail`,
  MASTER_COMPANY: `company`,
  MASTER_POSITION: `position`,
  MASTER_DEPARTMENT: `department`,
  APPROVE_LEVEL1: `approver_level1`,
  APPROVE_LEVEL1_EMAIL: `approver_level1_email`,
  MODELMENU: `modelmenu`,
  SALES_ORDER_H: `saleorder_h`,
  SALES_ORDER_D: `saleorder_d`,
  POSITION: `position`,
  JOBTITLE: `jobtitle`,
  ACTIVITY: "actiivity",
  LEAVE_QUOTA: "leave_quota",
  LEAVE_HISTORY: `leave-history`,
  LEAVE_HISTORY_QUOTA_DETAIL: `leave-history-quota-detail`,
  LEAVE_HOLIDAY: `leave-holiday`,
  LEAVE_TYPE: `leave-type`,
  LEAVE_APPROVE_DETAIL: `leave-approve-detail`,
  INVOICE_H: `invoice_h`,
  INVOICE_SO: `invoice_so`,
  INVOICE_TS: `invoice_ts`,
  INVOICE_DOCREF: `invoice_docref`,
  INVOICE_MISCELLANEOUS: `miscellaneous`,
  INVOICE_TITLE: `invoice_title`,
  TRC_H: `trc_h`,
  TRC_D: `trc_D`,
  IM_PRINCIPAL: `im_principal`,
  IM_PARTICIPANT: `im_participant`,
  DOCTYPE: `doctype`,
  DOCTYPE_TEMPLATE: `doctitle_template`,
  DOCDROPDOWN: `docdropdown`,
  DOCTRANS: `doctrans`,
  DOCTRANS_H: `doctrans_h`,
  DOC_RUNNING: `doc-running`,
  IM_TRANSACTION: `im_transaction`,
  IM_TRANSACTION_DEPENDENT: `im_transaction_dependent`,
  INV_MIS_LIST: `inv_mis_list`,
  IM_MASTER_PLACE_OF_ISSUE: `im_master_place_of_issue`,
  IM_MASTER_WORK_PLACE_OF_ISSUE: `im_master_work_place_of_issue`,
  TRANSACTIONS_LOG: `transaction-log`,
  LOG_IN_OUT_LOG: `log-in-out-log`,
  TRANNING_H: `tranning_h`,
  TRANNING_D: `tranning_D`,
  TRANNING_EMAIL: `tranning_email`,
  TRANNING_USER: `tranning_user`,
  TRANNING_USER_D: `tranning_user_d`,
  API_PETTYCASH_H: `petty-cash-h`,
  API_PETTYCASH_D: `petty-cash-d`,
  FILE_ATTACH: `file-attach`,
  REGISTER_COMPANY: `register-company`,

  //--------URL IP AT LAW-------------------//
  MASTERTM: `mastertm`,
  OBJECTTYPE: `objecttype`,
  CONTACT: `contact`,
  TMUPDATESTATUS: `tmupdatestatus`,
  MASTERPT: `masterpt`,
  DOCUMENTTYPE: `documenttype`,
  APPOINTMENT: `appointment`,
  COUNTRY: `country`,
  NOTICE: `notice`,
  POA: `poa`,
  OCR: `ocr`,
  OCRKW: `ocrkw`,
  IP_PRICE_LIST: `ip_price_list`,
};
