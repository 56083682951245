//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { server, imageUrl } from "@/services/constants";
import api from "@/services/api";
export default {
  name: "Header",
  data() {
    return {
      mycompany:[],
      th_name: "",
      eng_name: "",
      department_name: "",
      image: "",
      image_profile: "",
      user_text_profile: "",
    };
  },
  async mounted() {
    await this.getMyCompany();
    await this.loadEmpdata();

  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    getENV() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
    async loadEmpdata(){
      const res_emp = await api.getAccountByidMenu(localStorage.getItem(server.USER_ID))

      this.th_name = res_emp.data[0].name;
      this.eng_name = res_emp.data[0].eng_name;
      this.department_name = res_emp.data[0].department_name;
      this.image = res_emp.data[0].image;


      
      if (this.image != "" && this.image != null) {
        this.image_profile = imageUrl + "/" + this.image;
      }else{
          this.user_text_profile = this.eng_name.slice(0, 1) + this.eng_name.split(" ")[2].substring(0, 1)
      }
    },
    async getMyCompany() {
      const result = await api.getCompanyByID(
          localStorage.getItem(server.COMPANYID)
        );
        this.mycompany = result.data;
    },
    onClickLogOff() {
      this.$store.state.navMenu = false;
      this.$store.dispatch("doLogout");
    },
    resetPassword() {
      // alert("Reset Password");
      this.$router.push("/my-profile");
    },
  },
};
