//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "./language/Solist.js";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "./computing/tolocalestringnumber";
import unity from "@/unity/unity";

export default {
  name: "SO-list",
  data(vm) {
    return {
      search_status: "",
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Draft", "Submit"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],

      headers: [
        {
          text: "Document No.",
          value: "saleorder_id",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "130px",
        },
        {
          text: "Date",
          value: "po_date_show",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "100px",
        },
        {
          text: "Customer",
          value: "name",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "210px",
        },
        {
          text: "Total Amount",
          value: "sum_line_amt",
          class: "bg-colorth tabletextwhite",
          divider: true,
          align: "end",
          width: "130px",
        },
        {
          text: "Currency",
          value: "currency",
          class: "bg-colorth tabletextwhite",
          divider: true,
          align: "end",
          width: "100px",
        },

        {
          text: "Document Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "150px",
        },

        {
          text: "Editor",
          value: "user_create",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "90px",
        },
        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
          divider: true,
        },
      ],
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loaddataSO();
  },
  methods: {
    editItem() {},

    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    finddatafromdate() {
      //alert(this.datefrom)
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.search_status == "") {
          if (this.datefrom != null && this.dateto != null) {
            if (item.po_date >= this.datefrom && item.po_date <= this.dateto) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            return item;
          }
        } else {
          if (this.datefrom != null && this.dateto != null) {
            if (
              item.po_date >= this.datefrom &&
              item.po_date <= this.dateto &&
              item.status == this.search_status
            ) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            if (item.status == this.search_status) {
              return item;
            }
          }
        }
      });
    },
    findChaege() {
      // alert(this.search_status)
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom == null || this.dateto == null) {
          if (this.search_status != null) {
            if (item.status == this.search_status) {
              return item;
            }
          } else {
            return item;
          }
        }
        if (this.datefrom != null && this.dateto != null) {
          if (this.search_status != null) {
            if (
              item.status == this.search_status &&
              item.po_date >= this.datefrom &&
              item.po_date <= this.dateto
            ) {
              return item;
            }
          } else {
            if (item.po_date >= this.datefrom && item.po_date <= this.dateto) {
              return item;
            }
          }
        }
      });
    },
    onClicktoadSolist(id) {
      this.$router.push(`/edit-so/${id}`);
    },
    async loaddataSO() {
      const result = await api.getSalesOrderBycompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      console.log(result.data);
      this.dataAll = result.data;
      // console.log("dateAll:")
      //console.log(this.dataAll)
      this.mDataArray = result.data;
      this.mDataArray.forEach((item) => {
        item.sum_line_amt = tolocalestringnumber(item.sum_line_amt);
      });

      // this.$showLoader();
      this.$hideLoader();
    },
  },
};
