//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { imageUrl } from "@/services/constants";
export default {
  data: () => ({
    department_show: "",
    name_show: "",
    id_show: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    ip_check: "no",
    disipcheck: true,
    search_dep: "",
    search_pos: "",
    search_status: "",
    isShowPassword: false,
    commonRules: [(v1) => !!v1 || "This is required"],
    prename_t_h: ["นาย", "นาง", "นางสาว"],
    prename_e_n: ["Mr", "Miss", "Mrs."],
    emp_status: [
      { id: "A", status_name: "Active" },
      { id: "D", status_name: "Inactive" },
    ],
    user_role: [
      { id: "COMPANY_ADMIN", status_name: "COMPANY_ADMIN" },
      { id: "EMPLOYEE", status_name: "EMPLOYEE" },
    ],
    signature: "",
    defaultwordbuttonsig1: "Signature",
    showsig1: false,
    showbuttonsavesig1: false,
    options: {
      penColor: "#000000",
      backgroundColor: "rgb(255,255,255)",
    },
    status_list: ["Active", "Inactive"],
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    dialogAdd: false,
    text_color: "text-h5 green--text text-center",
    title: "green",
    message: "green",
    fullPage: true,
    search: "",
    headers: [
      //{ text: "Title", value: "prename_t_h" },
      {
        text: "Firstname(Thai)",
        align: "start",
        sortable: false,
        value: "firstname",
      },
      { text: "Lastname(Thai)", value: "lastname", sortable: false },
      // { text: "เบอร์", value: "phone" },
      // { text: "username", value: "username" },
      // { text: "role", value: "role" },
      // { text: "รหัสพนักงาน", value: "emp_i_d" },
      // { text: "prename_e_n", value: "prename_e_n" },
      { text: "Firstname(English)", value: "firstname_e_n", sortable: false },
      { text: "Lastname(English)", value: "lastname_e_n", sortable: false },
      //{ text: "abbname_e_n", value: "abbname_e_n" },
      { text: "Company", value: "company_name", sortable: false },
      { text: "Department", value: "department_name", sortable: false },
      { text: "Position", value: "position_name", sortable: false },
      //{ text: "Rate", value: "emp_rate" },
      { text: "Status", value: "emp_status_name", sortable: false },
      //{ text: "email", value: "email" },
      // { text: "password", value: "password" },
      //{ text: "image", value: "image" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    repalceId: 0,
    desserts: [],
    dataAll: [],
    replaceId: "",
    position: [],
    approver: [],
    department: [],
    department_list: [],
    position_list: [],
    company: [],
    editedIndex: -1,
    editedItemDel: {
      emp_status: "D",
      user_update: 0,
    },
    editedItem: {
      ip_check: "",
      prename_t_h: "",
      firstname: "",
      lastname: "",
      phone: "",
      username: "",
      emp_i_d: "",
      prename_e_n: "",
      firstname_e_n: "",
      lastname_e_n: "",
      abbname_e_n: "",
      company_i_d: 0,
      department_i_d: 0,
      position_i_d: 0,
      authorize_id: 0,
      emp_rate: 0.0,
      emp_status: "A",
      email: "",
      password: "",
      image: null,
      role: "",
      user_role: "",
      filename: "demo.png",
      approver_level1: 0,
      approver_level2: 0,
      approver_level3: 0,
      ip_authentication:""
    },
    defaultItem: {
      prename_t_h: "",
      firstname: "",
      lastname: "",
      phone: "",
      username: "",
      role: "",
      user_role: "",
      emp_i_d: "",
      prename_e_n: "",
      firstname_e_n: "",
      lastname_e_n: "",
      abbname_e_n: "",
      company_i_d: 0,
      department_i_d: 0,
      position_i_d: 0,
      emp_rate: 0.0,
      emp_status: "",
      email: "",
      password: "",
      image: "",
      approver_level1: 0,
      approver_level2: 0,
      approver_level3: 0,
      ip_authentication: "",
    },
    imageURL: null,
    toggleDisable: true,
    edit_item_id: 0,
    userId: 0,
    authorize: [],
    authorize_view: false,
    authorize_add: false,
    authorize_edit: false,
    authorize_del: false,
    authorize_id: 0,
    initial_data1: "",
    company_id:0,
    dialogDecComCode: false,
    slect_app2: false,
    slect_app_b2: true,
    slect_app3: false,
    slect_app_b3: false,
    dialogApprover: false,
    
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Employee" : "Edit Employee";
    },

    filteredData() {
      return this.desserts.filter((row) => {
        const name = row.firstname?.toLowerCase();
        const lastname = row.lastname?.toLowerCase();
        const username = row.username?.toLowerCase();
        const keyword = this.search.toLowerCase();

        return (
          name.includes(keyword) ||
          lastname.includes(keyword) ||
          username.includes(keyword)
        );
      });
    },
  },
  components: {
    SuccessDialog,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogApprover(val) {
      val || this.closeReplace();
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    this.initialize();
  },

  async mounted() {
    await api.checkVersion();
    // alert(localStorage.getItem(server.ROLE));
    this.showdefaultsignature();
    // ----------------- Check Authorize ---------------------------
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------
    this.company_id = parseInt(localStorage.getItem(server.COMPANYID));
    await this.loadAccounts();
    let comp_id = localStorage.getItem(server.COMPANYID);
    await this.loadCompanyMaster(comp_id);
    await this.loadDepartmentMaster(comp_id);
    await this.loadPositionMaster(comp_id);

    await this.loadAuthorize();

    // this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
  },

  methods: {
    async desComCode(){
                this.dialogDecComCode = true;
            },
    async checkbox_ip(){
      // alert("testch")
      if(this.ip_check == "no"){
        this.disipcheck = true;
        this.editedItem.ip_authentication = "";
      }else{
        this.disipcheck = false;
      }

    },
    async changeCompany(selectObj) {
      // console.log(selectObj)
      // alert(selectObj);
      await this.loadDepartmentMaster(selectObj);
      await this.loadPositionMaster(selectObj);
    },
    finddata() {
      this.desserts = this.dataAll.filter((item) => {
        if (this.search_dep) {
          if (this.search_pos) {
            if (this.search_status) {
              if (
                item.department_name == this.search_dep &&
                item.position_name == this.search_pos &&
                item.emp_status_name == this.search_status
              ) {
                return item;
              }
            } else {
              if (
                item.department_name == this.search_dep &&
                item.position_name == this.search_pos
              ) {
                return item;
              }
            }
          } else {
            if (this.search_status) {
              if (
                item.department_name == this.search_dep &&
                item.emp_status_name == this.search_status
              ) {
                return item;
              }
            } else {
              if (item.department_name == this.search_dep) {
                return item;
              }
            }
          }
        } else {
          if (this.search_pos) {
            if (this.search_status) {
              if (
                item.position_name == this.search_pos &&
                item.emp_status_name == this.search_status
              ) {
                return item;
              }
            } else {
              if (item.position_name == this.search_pos) {
                return item;
              }
            }
          } else {
            if (this.search_status) {
              if (item.emp_status_name == this.search_status) {
                return item;
              }
            } else {
              return item;
            }
          }
        }
      });
    },
    addEmp() {
      this.toggleDisable = true;
    },
    getImage() {
      // console.log(`url_img : ${imageUrl}/${this.editedItem.image}`);
      return `${imageUrl}/${this.editedItem.image}`;
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // for preview image
        this.imageURL = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);

      // for upload image
      this.editedItem.image = event.target.files[0];

      // console.log("result image body : ", this.editedItem.image);
    },
    async loadAccounts() {
      var result;
      var resultList;

      // if (localStorage.getItem(server.ROLE) == "ADMIN") {
      //   result = await api.getAccounts();
      //   resultList = await api.getAccountslist();
      // } else {
      result = await api.getAccounts_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      resultList = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );
      // }
      // const result = await api.getAccounts();
      // const result = await api.getAccounts_by_ComID(
      //   localStorage.getItem(server.COMPANYID)
      // );
      this.dataAll = result.data;
      this.desserts = this.dataAll;
      console.log(this.desserts);
      // const resultList = await api.getAccountslist();
      // const resultList = await api.getAccountslist_by_ComID(
      //   localStorage.getItem(server.COMPANYID)
      // );
      this.approver = resultList.data;

      //console.log(this.approver)
      // result.data.forEach((item) => {
      //   this.approver.push(item.firstname);
      // });

      // this.$showLoader();
      this.$hideLoader();
    },

    async loadCompanyMaster(id) {
      const result = await api.getCompanyMaster(id);
      this.company = result.data;
    },

    async loadDepartmentMaster(id) {
      const result = await api.getAllDepartment_By_CompanyID(id);
      // console.log("result.data : ", JSON.stringify(result.data));
      this.department = result.data;
      // console.log('test dapart')
      // console.log(this.department)
      result.data.forEach((item) => {
        this.department_list.push(item.department_name);
      });
    },
    async loadAuthorize() {
      // const res_get = await api.getSettingGroupMenu();
      // console.log("res loadAuthorize : ", JSON.stringify(res_get));

      var res_get;

      // if (localStorage.getItem(server.ROLE) == "ADMIN") {
      //   res_get = await api.getSettingGroupMenu();
      //   this.authorize = res_get.data;
      // } else {
      res_get = await api.getSettingGroupMenu_By_CompanyID(
        localStorage.getItem(server.COMPANYID)
      );
      // console.log("res loadAuthorize : ", JSON.stringify(res_get.data));
      this.authorize = res_get.data;
      // }

      // this.authorize = res_get.data;
    },

    async loadPositionMaster(id) {
      const result = await api.getPositionMaster(id);
      // console.log("result.data : ", JSON.stringify(result.data));
      this.position = result.data;

      result.data.forEach((item) => {
        this.position_list.push(item.name);
      });
    },

    async loadDetailAccount(id) {
      this.showsig1 = true;
      // console.log("loadDetailAccount:", id + "//");
      const result = await api.getAccountDetail(id);
      this.initial_data1 = result.data[0].signature;
      console.log("loadDetailAccount result: ");
      console.log(result);
      this.editedItem = Object.assign({}, result.data[0]);
      this.showdefaultsignature();
      this.showsig1 = true;
      this.showbuttonsavesig1 = true;

      if(Number(result.data[0].approver_level3 != 0)){
        this.slect_app2 = true;
        this.slect_app3 = true;
        this.slect_app_b2 = false;
        // this.slect_app_b3 = false;
      }else if(Number(result.data[0].approver_level3 == 0)) {
        if(Number(result.data[0].approver_level2 != 0)){
          this.slect_app2 = true;
          this.slect_app_b2 = false;
          this.slect_app_b3 = true;
        }
      }
    },

    initialize() {
      this.desserts = [
        {
          prename_t_h: "prename_t_h",
          firstname: "firstname",
          lastname: "lastname",
          emp_i_d: "emp_i_d",
          prename_e_n: "prename_e_n",
          firstname_e_n: "firstname_e_n",
          lastname_e_n: "lastname_e_n",
          abbname_e_n: "abbname_e_n",
          company_i_d: "company_i_d",
          department_i_d: "department_i_d",
          position_i_d: "position_i_d",
          emp_rate: "emp_rate",
          emp_status: "emp_status",
          role: "",
        },
      ];
    },

    editItem(item) {
      // console.log(item);
      this.toggleDisable = false;
      this.loadDetailAccount(item.id);
      this.edit_item_id = item.id;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if(this.editedItem.ip_authentication != "" && this.editedItem.ip_authentication != "0" && this.editedItem.ip_authentication != null){
        this.ip_check = "yes";
        this.disipcheck = false;
        this.editedItem.ip_authentication = "";
      }else{
        this.ip_check = "no";
        this.disipcheck = true;
      }

      // alert(item.company_i_d);
      // alert(item.department_i_d);
      // alert(item.position_i_d);
      this.loadDepartmentMaster(item.company_i_d);
      this.loadPositionMaster(item.company_i_d);
      // const res_acc = await api.getAccountDetail(
      //     localStorage.getItem(server.item.id)
      //  );
      // this.initial_data1 = res_acc.data[0].signature;
      // this.edi

      this.dialog = true;
    },

    deleteItem(item) {
      // this.editedIndex = this.desserts.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.dialogDelete = true;

      this.edit_item_id = item.id;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // const account = this.desserts[this.editedIndex];
      // const result = await api.deleteUserAccount(account.id);
      // this.closeDelete();

      this.editedItemDel.user_update = this.userId;
      this.desserts.splice(this.editedIndex, 1);
      const res_del = await api.deleteUserAccount(
        this.edit_item_id,
        this.editedItemDel
      );
      if (res_del.status == 200) {
        this.setupAlertDialog(
          true,
          "Success",
          "Delete data success",
          "text-h5 green--text text-center"
        );
        this.initialize();
      } else {
        this.setupAlertDialog(
          true,
          "Failed",
          "Delete data Failed",
          "text-h5 red--text text-center"
        );
      }

      this.closeDelete();
      await this.loadAccounts();
    },

    close() {
      this.$refs.form.reset()
      this.slect_app2 = false;
      this.slect_app_b2 = true;
      this.slect_app3 = false;
      this.slect_app_b3 = false;

      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.clearsig1();
      this.showsig1 = false;
      this.showbuttonsavesig1 = false;
      this.defaultwordbuttonsig1 = "signature";
      
     

 
      
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeReplace() {
      this.dialogApprover = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.repalceId = 0;
      });
    },
    async save() {


      // if (this.editedItem.firstname == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed",
      //     "Please enter first name",
      //     "text-h5 red--text text-center"
      //   );
      //   this.$refs["firstname_eng"].focus();
      //   return;
      // }

      if (this.editedItem.prename_e_n == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["title_eng"].focus();
        return;
      }
      if (this.editedItem.firstname_e_n == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["firstname_eng"].focus();
        return;
      }
      if (this.editedItem.lastname_e_n == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["lastname_eng"].focus();
        return;
      }

      if (
        !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.editedItem.email
        )
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["email"].focus();
        return;
      }
      if (this.editedItem.company_i_d == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Company"].focus();
        return;
      }

      if (this.editedItem.department_i_d == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Department"].focus();
        return;
      }

      if (this.editedItem.position_i_d == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Position"].focus();
        return;
      }
      if (this.editedItem.authorize_id == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Authorization"].focus();
        return;
      }

      if (this.editedItem.username == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["username"].focus();
        return;
      }
      if (this.editedItem.password == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["password"].focus();
        return;
      }

     
      if (this.editedItem.approver_level1 == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Approver1"].focus();
        return;
      }

      this.slect_app2 = false;
      this.slect_app_b2 = true;
      this.slect_app3 = false;
      this.slect_app_b3 = false;

      let formData = new FormData();
      formData.append("emp_i_d", this.editedItem.emp_i_d);
      formData.append("user_role", this.editedItem.user_role);
      formData.append("username", this.editedItem.username);
      formData.append("password", this.editedItem.password);
      formData.append("email", this.editedItem.email);
      formData.append("phone", this.editedItem.phone);
      formData.append("prename_t_h", this.editedItem.prename_t_h);
      formData.append("firstname", this.editedItem.firstname);
      formData.append("lastname", this.editedItem.lastname);
      formData.append("prename_e_n", this.editedItem.prename_e_n);
      formData.append("firstname_e_n", this.editedItem.firstname_e_n);
      formData.append("lastname_e_n", this.editedItem.lastname_e_n);
      formData.append("abbname_e_n", this.editedItem.abbname_e_n);
      formData.append("company_i_d", this.editedItem.company_i_d);
      formData.append("department_i_d", this.editedItem.department_i_d);
      formData.append("position_i_d", this.editedItem.position_i_d);
      formData.append("emp_rate", this.editedItem.emp_rate);
      formData.append("emp_status", this.editedItem.emp_status);
      formData.append("authorize_id", this.editedItem.authorize_id);
      formData.append("image", this.editedItem.image);
      formData.append("approver_level1", this.editedItem.approver_level1);
      formData.append("approver_level2", this.editedItem.approver_level2);
      formData.append("approver_level3", this.editedItem.approver_level3);
      formData.append("ip_authentication", this.editedItem.ip_authentication);
      formData.append("signature", this.initial_data1);
      //alert(this.editedItem.approver_level1)
      //console.log("formData : ", formData);

      if (this.editedIndex > -1) {
        isShowPassword: false, formData.append("user_update", this.userId);

        Object.assign(this.desserts[this.editedIndex], this.editedItem);
        const id = this.desserts[this.editedIndex].id;
        // alert(id);
        // const payload = this.editedItem;
        const res_edit = await api.updateUserAccount(id, formData);

        if (res_edit.data.message) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            res_edit.data.message,
            "text-h5 red--text text-center"
          );
          await this.loadAccounts();
        } else {
          if(Number(this.editedItem.company_i_d == 1)){

            let data_edit_sme = {
              first_name: this.editedItem.firstname_e_n,
              last_name: this.editedItem.lastname_e_n,
              email: this.editedItem.email,
              status:"",
              department_id: Number(this.editedItem.department_i_d),
            }

            if(this.editedItem.emp_status == "A"){
              data_edit_sme.status = "Active";
            }else{
              data_edit_sme.status = "Inactive";
            }
            console.log('data_add_sme: ')
            console.log(data_edit_sme)

            await api.editUserSmeMate(id,data_edit_sme)
          }

          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Success",
            "Edit data success",
            "text-h5 green--text text-center"
          );
          await this.loadAccounts();
        }
      } else {
        const res_cusername = await api.getCheckUsername(
          this.editedItem.username,
          Number(localStorage.getItem(server.COMPANYID))
        );
        if (res_cusername.data[0].status_username > 0) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            "Username already in used, please choose another username.",
            "text-h5 red--text text-center"
          );
          return;
        }
        formData.append("user_create", this.userId);

        // Insert
        const res_add = await api.addUserAccount(formData);

        if (res_add.data.message) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            res_add.data.message,
            "text-h5 red--text text-center"
          );
        } else {
          if(Number(res_add.data.company_i_d == 1)){
            let data_add_sme = {
              id: res_add.data.id,
              user_name: res_add.data.username,
              first_name: res_add.data.firstname_e_n,
              last_name: res_add.data.lastname_e_n,
              email: res_add.data.email,
              password: this.editedItem.password,
              role_id: 2,
              status:"",
              user_type: "User",
              department_id: Number(res_add.data.department_i_d),
              assign_permission: 0,
              expiration_date: "2099-01-01 00:00:00",
              user_create: res_add.data.user_create 
            }

            if(this.editedItem.emp_status == "A"){
              data_add_sme.status = "Active";
            }else{
              data_add_sme.status = "Inactive";
            }
            console.log('data_add_sme: ')
            console.log(data_add_sme)

            await api.addUserSmeMate(data_add_sme)
          }
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Success",
            "Add data success",
            "text-h5 green--text text-center"
          );
          await this.loadAccounts();
        }
      }

      this.close();
    },

    async saveReplace() {
      // console.log(data)
      if (this.repalceId == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.editedItem.approver_level1 == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.editedItem.approver_level2 == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.editedItem.approver_level3 == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        return;
      }
      let data1 = {};
      let data2 = {};
      let data3 = {};
      // alert(this.editedItem.approver_level1)
      data1.approver_level1 = this.editedItem.approver_level1;
      data2.approver_level2 = this.editedItem.approver_level2;
      data3.approver_level3 = this.editedItem.approver_level3;

      const res_rep1 = await api.replaceUserAccount1(this.repalceId, data1);
      const res_rep2 = await api.replaceUserAccount2(this.repalceId, data2);
      const res_rep3 = await api.replaceUserAccount3(this.repalceId, data3);
      if (res_rep1.data.message) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          res_rep1.data.message,
          "text-h5 red--text text-center"
        );
        await this.loadAccounts();
      } else {
        if (res_rep2.data.message) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            res_rep1.data.message,
            "text-h5 red--text text-center"
          );
          await this.loadAccounts();
        } else {
          if (res_rep3.data.message) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Failed",
              res_rep1.data.message,
              "text-h5 red--text text-center"
            );
            await this.loadAccounts();
          } else {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Success",
              "replace data success",
              "text-h5 green--text text-center"
            );
            await this.loadAccounts();
          }
        }

        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Success",
        //   "replace data success",
        //   "text-h5 green--text text-center"
        // );
        // await this.loadAccounts();
      }

      this.closeReplace();
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    editsig1() {
      this.showsig1 = true;
      this.showbuttonsavesig1 = true;
      //await Vue.nextTick();
      this.$refs.firstsignaturePad.resizeCanvas();
      this.$refs.firstsignaturePad.fromDataURL(this.initial_data1);
      this.$refs.firstsignaturePad.openSignaturePad();
    },
    clearsig1() {
      this.$refs.firstsignaturePad.resizeCanvas();
      this.$refs.firstsignaturePad.clearSignature();
      this.initial_data1 = "";
    },
    savesig1() {
      this.defaultwordbuttonsig1 = "Edit";
      this.showbuttonsavesig1 = false;

      const { isEmpty, data } = this.$refs.firstsignaturePad.saveSignature();
      this.initial_data1 = data;
      this.$refs.firstsignaturePad.resizeCanvas();
      this.$refs.firstsignaturePad.fromDataURL(this.initial_data1);
      //alert(this.signature)
      this.showbuttonsavesig1 = false;
      this.$refs.firstsignaturePad.lockSignaturePad();
    },
    showdefaultsignature() {
      this.$nextTick(() => {
        this.$refs.firstsignaturePad.resizeCanvas();
        this.$refs.firstsignaturePad.fromDataURL(this.initial_data1);

        // this.$refs.firstsignaturePad.lockSignaturePad();
        // this.$refs.firstsignaturePad.resizeCanvas();
        // this.$refs.firstsignaturePad.lockSignaturePad();
      });
    },
    async slect_approver(mode){
      // alert(mode);
      if(mode == 2){
        this.slect_app2 = true;
        this.slect_app_b2 = false;
        this.slect_app_b3 = true;
      }else if(mode == 3){
        this.slect_app3 = true;
        this.slect_app_b2 = false;
        this.slect_app_b3 = false;
      }
    },
    async showApproverData(){
      // alert(this.repalceId)
      var data_show = {};
      data_show = this.desserts.find(approver => approver.id === this.repalceId);
      console.log(data_show)
      this.name_show = data_show.prename_e_n + `. ` + data_show.firstname_e_n + ` ` + data_show.lastname_e_n;
      this.id_show = data_show.id;
      this.department_show = data_show.department_name;
    },
  },
};
